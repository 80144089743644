<template>
    <v-container fluid>
        <headerVue :title="$t('menu.equipments')" />
        <v-row align-content="center" justify="end">
            <v-col md="3">
                <a href="/agregar-equipo-computo">
                    <v-btn block color="primary" elevation="2" x-large>Crear Equipo</v-btn>
                </a>
            </v-col>
            <v-col md="3" offset-md="6">
                <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        <v-row>

            <v-col cols="12" style="text-align:center;">
              <v-progress-circular v-if="loading"
              :size="70"
              :width="7"
              color="purple"
              indeterminate></v-progress-circular>

              <v-alert v-if="deleteSuccess" type="success">El equipo fue dado de baja</v-alert>
            </v-col>

            <v-col cols="12" v-if="!loading">
                <v-data-table
                    :headers="headers"
                    :items="equipments"
                    :search="search"
                    class="elevation-2 text--dark table-striped"
                    loading-text="Cargando datos... Por favor espere"
                    :items-per-page="5"
                    :no-results-text="'No se encontraron incidencias'"
                    :no-data-text="'No hay datos'"
                    :footer-props="{
                    'items-per-page-text': 'Equipos por página',
                    'items-per-page': [5, 10, 15],
                    'items-per-page-all-text': 'Todos',
                    'items-per-page-options': [5, 10],
                    'loading-text': 'Obteniendo datos... Por favor espere',
                    'no-data-text': 'No hay datos...',
                    'no-results-text': 'No se encontraron incidencias',
                    }"
                    >
                    <template v-slot:no-data>
                        <v-alert :value="true" color="#ffffff">
                            <p style="color: #ff0000">No hay equipos registrados...</p>
                        </v-alert>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td class="text-sm-center">{{ item.numeroDeSerie }}</td>
                                <td class="text-sm-center">{{ item.marcas }}</td>
                                <td class="text-sm-center">{{ item.modelo }}</td>
                                <td class="text-sm-center">{{ item.fechaDeCompra | moment(" DD/MM/YYYY hh:mm a") }}</td>
                                <td class="text-sm-center">{{ item.fechaFinGarantia | moment(" DD/MM/YYYY hh:mm a")  }}</td>
                                <td class="text-sm-center">{{ item.tipoequipo }}</td>
                                <td class="text-sm-center">{{ item.memoria }}</td>
                                <td class="text-sm-center">{{ item.tamdd }}</td>
                                <td class="text-sm-center">{{ item.tipoDiscoDuro }}</td>
                                <td class="justify-center layout px-0">
                                    <v-icon color="gray" @click="edit(item.id)">mdi-pencil-outline</v-icon>
                                    <v-icon color="red" @click="deleteMachine(item.id)">mdi-trash-can</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import headerVue from "../../components/header.vue"
    export default {
      components: {
        headerVue,
      },
      data() {
        return {
          deleteSuccess: false,
			    loading: true,
          search: "",
          headers: [
            {
              text: "nº Serie",
              align: "center",
              sortable: false,
              value: "numeroDeSerie",
            },
            { text: "Marca", align: "center", value: "marcas" },
            { text: "Modelo", align: "center", value: "modelo" },
            { text: "Fecha Compra", align: "center", value: "fechaDeCompra" },
            { text: "Fecha Garantia", align: "center", value: "fechaFinGarantia" },
            { text: "Tipo", align: "center", value: "tipoequipo" },
            { text: "Memoria", align: "center", value: "memoria" },
            { text: "Disco Duro", align: "center", value: "tamdd" },
            { text: "Tipo HD", align: "center", value: "tipoDiscoDuro" },
            { text: "Opciones", align: "center", sortable: false },
          ],
          equipments: [],
          back: "",
        }
      },
      methods: {
        deleteMachine(id) {
          
          this.deleteSuccess = true;
          this.equipments = this.equipments.filter((equipment) => equipment.id !== id);

          axios.delete( 'https://consubanco.azurewebsites.net/api/CargaEquipoComputo/' + id )
          .then(resp => {
            
            window.location.reload();
          })
          .catch(err => {
            
            console.warn(err);
          });
        },
        edit(id) {
          
          this.$router.push( "/editar-equipo/"+ id );
        },
        inicialization() {
          
          axios.get('https://consubanco.azurewebsites.net/api/CargaEquipoComputo')
          .then(resp => {
              this.equipments = resp.data;
              this.loading = false;
          })
          .catch(err => {
              // Handle Error Here
              console.error(err);
          });
        },
      },
      beforeMount() {
        //this.inicialization()
      },
      created() {
        //this.inicialization()
      },
      mounted() {
        this.inicialization()
      },
    }
</script>